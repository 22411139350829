import React from 'react'
import { Link } from 'gatsby'
import { links } from '../content/site'
import { Heading, Text, SEO } from '../components/index'
import { ArrowUp } from '../components/icons/index'
import local from './contact.module.scss'

export default () => (
  <div className="modal modal-blue">
    <SEO title={'Contact'} />
    <div className={`grid ${local.grid}`}>
      <a className={local.close} href="/">
        {' '}
        [ close ]
      </a>
      <div className={`row pt4-xs pb4-xs ${local.row}`}>
        <div className="col col-10-xs col-1-xs-offset">
          <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
            <Heading size="large" dangerouslySetInnerHTML={{ __html: links.contactPage.name }} />
          </Link>
          <Text size="medium" font="cera" dangerouslySetInnerHTML={{ __html: links.contactPage.email }} />
          <a href="/cy.wtf.asc" rel="pgpkey" className={local.pgp}>
            [ pgp ]
          </a>
        </div>
        <div className="col col-10-xs col-1-xs-offset col-8-md col-8-lg">
          <Text
            size="medium"
            font="cera"
            weight="600"
            className="text"
            dangerouslySetInnerHTML={{ __html: links.contactPage.blurb }}
          />
        </div>
        <div className="col col-10-xs col-1-xs-offset">
          <div className={local.links}>
            <a href="/about" className={local.link}>
              About <ArrowUp />
            </a>
            <a href="/work-with-me/" className={local.link}>
              Work with me <ArrowUp />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)
